import { RegisterResource } from '~/resources/register'

export default defineNuxtRouteMiddleware(async (to) => {
  const slug = to.params.register as string
  const invitation = to.params.invitation as string
  const { setInvitation } = useRegisterInvitation(slug)

  const registerResource = new RegisterResource()
  const registerResponse = await registerResource.getWithInvitation(slug, invitation)

  if (!registerResponse.data.value?.item) {
    return navigateTo(`/${slug}`)
  }

  setInvitation(invitation)
  return
})
